<template>
    <div id="user-acount-expired">
        <the-navbar-vertical-public :theTitle="title" display-btn-help/>
        <div class="page">
            <div class="header">Activation de compte</div>
            <div class="content">
                <div class="link-expired">
                    <div class="emoticon">😲</div>
                    Votre lien n'est malheureusement plus valable.<br />
                    Mais ne vous inquiètez pas, il vous suffit de faire une nouvelle demande ci-dessous&nbsp;!
                </div>
                <vs-button
                    :disabled="emailSent"
                    @click="checkForm"
                    class="tw-w-full md:tw-w-auto mt-3 mb-8"
                >Renvoyer le lien</vs-button>
                <div class="link-legend">
                    Vous recevrez un e-mail avec un nouveau lien (valable 24h) pour définir votre mot de passe. <br />Après validation de celui-ci, vous pouvez vous connecter !
                </div>
            </div>
            <vs-alert
                    icon-pack="feather"
                    class="my-4 small mt-8 h-auto"
                    color="rgb(1, 207, 232)"
                >
                    <div class="pa-4">
                        <h4 class="titlex vs-alert--title">JE N’AI PAS REÇU DE MAIL, POURQUOI ?</h4><br>
                        <span class="small vs-alert--text">
                            La réception du mail peut se faire dans
                            les minutes qui suivent, regardez vos
                            <strong>spams</strong> au cas où. Il se
                            peut que vous n’ayez pas de compte.
                            Si le problème persiste, appelez notre
                            service client au <a href="tel:0184172373">01 84 17 23 73</a>
                            ou par mail <a href="mailto:contact@happytal.com"><strong>contact@happytal.com</strong></a>
                        </span>
                    </div>
                </vs-alert>
            <footer class="the-footer flex-wrap justify-between">
                <p>
                    <span>COPYRIGHT &copy;</span>
                    <span>{{ new Date().getFullYear() }} </span>
                    <a href="https://www.happytal.com" target="_blank" rel="nofollow">happytal</a>
                    <span class="tw-hidden sm:tw-inline-block">, Tous droits réservés</span>
                </p>
            </footer>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import TheNavbarVerticalPublic from '@/components/navbar/TheNavbarVerticalPublic.vue'
    import appsettings from '@/appsettings'

    export default {
        components: {
            TheNavbarVerticalPublic,
        },
        props: [
            'user_id'
        ],
        data () {
            return {
                title: 'Activation de compte',
                emailSent: false,
            }
        },
        methods: {
            checkForm () {
                this.$vs.loading()
                const baseApiPath = appsettings.VUE_APP_USER_API
                axios.post(`${baseApiPath}/resend-confirm`, {
                    userId: this.user_id,
                    passwordReset: false
                })
                .then((response) => {
                    this.$vs.loading.close()
                    this.showInfoToast(`Si votre compte existe, un email de ré-initialisation a été envoyé.`)
                    this.emailSent = true
                })
            },
            showInfoToast (text) {
                this.$toasted.show(text, {
                    theme: 'hx-toasted',
                    position: 'hx-bottom-left',
                    duration : 5000,
                    action : [
                        {
                            text : 'Fermer',
                            onClick : (e, to) => {
                                to.goAway(0)
                            }
                        }
                    ]
                })
            },
        }
    }
</script>
<style lang="scss" scoped>
#user-acount-expired {
    padding: 25px;

    .page {
        padding: 30px;

        .header {
            padding: 0px 0px 15px 0px;
            font-size: 22px;
            font-weight: 600;
        }

         .content {
            padding: 20px 20px 20px 20px;
            background: #FFFFFF;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
            border-radius: 8px;
            text-align: center;

            .link-expired {
                margin: 20px 0px 20px 0px;
                font-size: 16px;

                .emoticon {
                    font-size: 40px;
                }
            }
        }
        footer {

            p {
                margin: 0 auto;
                display: block;
                text-align: center;
            }
            a {
                color: #15D3C3;
            }
        }
        a {
            color: rgb(1, 207, 232);
        }
    }
}
.relative{
  position: relative;
}
@media (min-width: 575px) {
    .position{
      position: absolute;
      top: 13px;
      right: 10px;
      margin-bottom: 20px;
    }
}
@media (max-width: 575px) {
    .position{
      top:5px;
      left: 50x;
      margin-bottom: 10px;
    }
}

</style>
